<template>
    <div id="online">
        <img src="../../assets/images/education/banner_online.jpg" alt="" class="banner">
        <div class="course">
            <div class="topic">
                <img :src="require('@/assets/images/education/course.png')" alt="">
            </div>
            <div class="course-search">
                <input type="text" v-model="searchText" placeholder="请输入课程关键字搜索">
                <i class="icon-search" @click="search"></i>
            </div>
            <course-list-component :list="lessions" :maxNum="6" showPrice></course-list-component>
            <router-link to="/online/list/OTQ%3D/courses" class="btn-more">查看更多</router-link>
        </div>
        <div class="student-works">
            <div class="topic">
                <img :src="require('@/assets/images/education/topic_works.png')" alt="">
            </div>
            <div class="course-search">
                <input type="text" v-model="worksName" placeholder="请输入作品名称搜索">
                <i class="icon-search" @click="getStudentWorks"></i>
            </div>
            <div class="list">
                <div class="item" v-for="(item, index) in studentsWorks" :key="index">
                    <div class="pic">
                        <div class="inside-mask" v-if="item.videoUrl">
                            <span class="icon-play" @click="handlePlayVideo(item)"></span>
                        </div>
                        <img :src="eduStaticPath + 'pic/' + item.savePath + '.png'" alt="" @click="toEduPlatform(item)">
                    </div>
                    <div class="work-info">
                        <h2 @click="toEduPlatform(item)">{{ item.name }}</h2>
                        <div class="views">
                            <i class="icon-views"></i>
                            <!--                        {{ item.readCounts }}-->
                        </div>
                        <div class="student">
                            <img :src="eduStaticPath + item.headurl" alt="">
                            <span>{{ item.username }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <router-link to="/online/list/OTQ%3D/works" class="btn-more">查看更多</router-link>
        </div>
    
        <el-dialog
                :title="opusName"
                :visible.sync="videoDialogVisible"
                class="video-dialog"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :lock-scroll="false"
                append-to-body>
            <div class="video">
                <video width="100%" height="100%" controls :src="eduStaticPath + 'opus/' + videoUrl"></video>
<!--                <video width="100%" height="100%" controls ref="video">-->
<!--                    <source src="" type="video/mp4">-->
<!--                </video>-->
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import CourseListComponent from './component/courseListComponent.vue'
    import {urls, eduStaticPath, eduPlatformUrl, eduAI7PlatformUrl} from '@/lib/common'
    export default {
        name: "Online",
        components: {
            CourseListComponent
        },
        data() {
            return {
                eduStaticPath,
                lessions: [],
                studentsWorks: [],
                searchText: '',
                worksName: '',
                currentPage: 1,
                pageSize: 8,
                videoDialogVisible: false,
                opusName: '',
                videoUrl: '',
                
            };
        },
        methods: {
            getCourseList() {
                const params = {
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                }
                if (this.searchText) params.name = this.searchText;
                this.$http.get(urls.getCourseList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.lessions = res.data.data.records;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getStudentWorks() {
                const params = {
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                }
                if (this.worksName) params.name = this.worksName;
                this.$http.get(urls.getStudentWorks, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.studentsWorks = res.data.data.records;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            search() {
                this.getCourseList()
            },
            handlePlayVideo(item) {
                this.videoDialogVisible = true;
                this.videoUrl = item.videoUrl;
                this.opusName = item.name
            },
            videoPreview() {
                const self = this;
                const url = urls.previewVideo + self.coursewareId
                self.$http({
                    url,
                    method: 'get',
                    responseType: 'blob'
                }).then((res) => {
                    let data = res.data;
                    let fileReader = new FileReader();
                    fileReader.onload = function() {
                        try {
                            let jsonData = JSON.parse(this.result);
                            if (jsonData.status) {
                                // 说明是普通对象数据，后台转换失败
                                self.$message.warning(jsonData.message)
                                switch(jsonData.status) {
                                    case 106:
                                        sessionStorage.setItem('prevPath', JSON.stringify({
                                            pathName: self.$route.name,
                                            params: self.$route.params
                                        }))
                                        setTimeout(() => {
                                            self.$router.push({
                                                path: '/login'
                                            })
                                        }, 2000)
                                        break;
                                    case 500:
                                        setTimeout(() => {
                                            self.$router.back();
                                        }, 2000)
                                        break;
                                    default:
                                        break;
                                }
                            }
                        } catch (err) {
                            // 解析成对象失败，说明是正常的文件流
                            const blob = res.data;
                            self.$refs.video.onload = function() {
                                window.URL.revokeObjectURL(self.$refs.video.src);
                            };
                            self.$refs.video.src = window.URL.createObjectURL(blob);
                        }
                    };
                    fileReader.readAsText(data);
                });
            },
            toEduPlatform(item) {
                var isChrome = this.getBrowserInfo() === 'Chrome';
                if(!isChrome){
                    this.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: '<p style="line-height: 150%;">我们十分抱歉，您当前使用的浏览器不支持邦宝教育编程平台所提供所有功能，<br/>我们建议您使用最新版的Google Chrome(谷歌)浏览器，以获得更佳的体验。</p>',
                        type: 'error',
                        duration: 5000,
                        center: true
                    })
                    return;
                }
                let url = item.device === 'AI7' ? eduAI7PlatformUrl : eduPlatformUrl
                window.open(`${url}?sp=${item.savePath}&name=${item.name}&mode=${item.mode}&device=${item.device}`);
            },
            getBrowserInfo() {
                var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
                var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
                var isIE = userAgent.indexOf("compatible") > -1
                    && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
                var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
                var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
                var isSafari = userAgent.indexOf("Safari") > -1
                    && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
                var isChrome = userAgent.indexOf("Chrome") > -1
                    && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

                if (isIE) {
                    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
                    reIE.test(userAgent);
                    var fIEVersion = parseFloat(RegExp["$1"]);
                    if (fIEVersion == 7) {
                        return "IE7";
                    } else if (fIEVersion == 8) {
                        return "IE8";
                    } else if (fIEVersion == 9) {
                        return "IE9";
                    } else if (fIEVersion == 10) {
                        return "IE10";
                    } else if (fIEVersion == 11) {
                        return "IE11";
                    } else {
                        return "0";
                    }
                    //IE版本过低
                    return "IE";
                }
                if (isOpera) {
                    return "Opera";
                }
                if (isEdge) {
                    return "Edge";
                }
                if (isFF) {
                    return "FF";
                }
                if (isSafari) {
                    return "Safari";
                }
                if (isChrome) {
                    return "Chrome";
                }
            }
        },
        mounted() {
            this.getCourseList()
            this.getStudentWorks()
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #online {
        /*background-color: #F2FCFF;*/
        background: #F2FCFF url("../../assets/images/education/online_bg2.png") no-repeat center top;
        background-size: 100% 102%;
        .banner {
            margin-bottom: 60px;
        }
        .course{
            width: 70%;
            margin: auto;
        }
    
        .course-search {
            text-align: center;
            margin: 3vw auto;
            input {
                height: 3vw;
                line-height: 3vw;
                min-width: 50%;
                background: #fff;
                border-radius: 2vw 0 0 2vw;
                padding: 0 12px;
                font-size: 1.458vw;
                color: #2F3E59;
            }
            .icon-search {
                display: inline-block;
                width: 3vw;
                height: 3vw;
                line-height: 3vw;
                box-sizing: border-box;
                background: #fff url("../../assets/images/education/icon_search.png") no-repeat center center;
                background-size: 50%;
                position: relative;
                top: 1vw;
                border-radius: 0 2vw 2vw 0;
                cursor: pointer;
            }
        }
        .student-works {
            padding: 100px 15% 120px;
            .list {
                display: flex;
                flex-wrap: wrap;
                .item {
                    width: 22%;
                    margin: 0 4% 4% 0;
                    box-sizing: border-box;
                    padding: 6px;
                    background: #fff;
                    border-radius: 0 0 1vw 0;
                    box-shadow: 0 5px 10px 1px rgba(190, 238, 252, 0.3);
                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                    .pic {
                        position: relative;
                        .inside-mask {
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background-color: rgba(0, 0, 0, .3);
                            .icon-play {
                                display: inline-block;
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                background: #fff url("../../assets/images/education/icon_play.png") no-repeat center center;
                                background-size: contain;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-left: -30px;
                                margin-top: -30px;
                                box-shadow: 0 0 10px 1px rgba(255, 255, 255, .8);
                                cursor: pointer;
                                opacity: 0.8;
                                transition: all .3s linear;
                                &:hover {
                                    box-shadow: 0 0 10px 1px rgba(69,114,160, .8);
                                }
                            }
                        }
                        >img {
                            width: 100%;
                            min-height: 150px;
                        }
                    }
                    h2 {
                        color: #2F3E59;
                        margin: 0.8vw 0;
                        white-space: nowrap;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        cursor: pointer;
                    }
                    >img {
                        cursor: pointer;
                    }
                    .views {
                        color: #CCCCCC;
                        .icon-views {
                            display: inline-block;
                            width: 1vw;
                            height: 0.723vw;
                            background: url("../../assets/images/icon_views_black.png") no-repeat center center;
                            background-size: 100%;
                            margin-right: 5px;
                        }
                    }
                    .student {
                        margin-top: 0.5vw;
                        padding: .5vw 0;
                        border-top: 1px solid #ddd;
                        line-height: 1.3vw;
                        color: #0A0A0A;
                        img {
                            width: 1.56vw;
                            margin-right: 0.6vw;
                        }
                    }
                }
            }
        }
    }

    @{deep} .el-dialog {
        border-radius: 10px 10px 0 0;
        text-align: center;
        color: #34254C;
        font-size: 18px;
        background-color: rgba(0, 0, 0, 0.85);
        width: 60%;
        .el-dialog__body {
            padding: 20px 0 0 0;
        }
        .el-dialog__title {
            color: #fff;
        }
        .el-icon-close {
            font-size: 24px;
            color: #fff;
        }
        .video {
            video {
                width: 100%;
                max-height: 600px;
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #online {
            .course{
                width: 100%;
                .course-search{
                    margin: 4% auto;
                    position: relative;
                    box-shadow: 0 0 10px #aaa;
                    border-radius: 8px;
                    overflow:hidden;
                    width: 90%;
                    input{
                        .fontFn(44,height);
                        .fontFn(44,line-height);
                        .fontFn(14);
                        width: 100%;
                        padding-right: 16%;
                    }
                    .icon-search{
                        position: absolute;
                        top:0;
                        right: 0;
                        .fontFn(44,width);
                        .fontFn(44,height);
                    }
                }
            }

            .course-search{
                margin: 4% auto;
                position: relative;
                box-shadow: 0 0 10px #aaa;
                border-radius: 8px;
                overflow:hidden;
                width: 90%;
                input{
                    .fontFn(44,height);
                    .fontFn(44,line-height);
                    .fontFn(14);
                    width: 100%;
                    padding-right: 16%;
                }
                .icon-search{
                    position: absolute;
                    top:0;
                    right: 0;
                    .fontFn(44,width);
                    .fontFn(44,height);
                }
            }

            .student-works{
                padding: 100px 5.2%;
                .list{
                    display:block;
                    .item{
                        display:inline-block;
                        vertical-align:top;
                        &:nth-child(2n){
                            margin-right:0;
                        }
                        width: 48%;
                        h2{
                            .fontFn(14);
                            margin:3% 0;
                            cursor: pointer;
                        }
                        >img {
                            cursor: pointer;
                        }
                        .views{
                            .icon-views{
                                .fontFn(20,width);
                                .fontFn(14,height);
                            }
                        }
                        .student{
                            margin-top:2%;
                            padding:3% 0;
                            .fontFn(12);
                            .fontFn(18,line-height);
                        }   
                    }
                }
            }
        }
    }

</style>